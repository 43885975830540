import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Container from '../components/Container'
import HeaderCarousel from '../components/HeaderCarousel'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import MetaTag from '../components/MetaTag'
import ExternalLink from '../components/ExternalLink'

const IndexPage = () => {
  return (
    <Layout>
      <TopVisual />
      <TopAbout />
      <TopNews />
      <TopService />
      <TopContact />
    </Layout>
  )
}

const TopVisual  = () => {
  return (
      <div className="relative lg:pt-[5.25rem] pt-[4.5rem] mb-16 bg-black">
        <HeaderCarousel />
        <div className='absolute inset-y-2/4 lg:inset-x-0 left-1/2'>
          <section className='lg:-translate-y-1/2 lg:translate-x-0 lg:mb-12 lg:pl-32 lg:w-fit lg:h-fit lg:text-left -translate-y-1/4 -translate-x-1/2'>
            <p className='font-bold text-white tracking-widest fullhd:text-8xl fullhd:leading-normal lg:text-left lg:leading-normal lg:text-6xl lg:py-4 text-3xl leading-normal text-center'>最高に<br />
              魅力的な<br />
              購買体験を。
            </p>
          </section>
        </div>
        <p className='absolute left-1/2 -translate-x-1/2 lg:bottom-16 bottom-8 text-white tracking-widest fullhd:text-2xl lg:text-xl text-md'>scroll</p>
        <ChevronDownIcon className='absolute left-1/2 -translate-x-1/2 lg:bottom-8 bottom-2 text-white' width={24} />
      </div>
  )
}

const TopAbout = () => {
  return(
    <section>
      <Container>
        <div className='relative text-center'>
          <StaticImage src='../images/onesel_iconG.png' alt='onesel_icon' className='fullhd:h-[20vh] fullhd:w-[20vh] h-[250px] w-[250px] my-24 xlg:my-12 xsm:my-36'/>
          <div className='absolute -translate-x-1/2 inset-y-1/4 left-1/2 -translate-y-[20%]'>
            <p className='w-[90vw] fullhd:text-3xl fullhd:leading-loose lg:text-2xl lg:leading-loose md:text-sm md:leading-loose text-sm leading-loose mb-8'>
              <span className='xsm:inline-block'>あらゆる製品やサービスには、作り手の情熱が宿る。</span><br className='xsm:hidden' /><br className='sm:hidden' /><br className='sm:hidden' />
              <span className='xsm:inline-block'>そこには知られざるストーリーがあり、</span>
              <span className='xsm:inline-block'>誰かの為になると信じた人たちがいる。</span><br className='xsm:hidden' /><br className='sm:hidden' /><br className='sm:hidden' />
              <span className='xsm:inline-block'>買う側にだって、ストーリーがある。</span>
              <span className='xsm:inline-block'>​欲しいと思った瞬間から、それは始まっている。</span><br /><br /><br className='sm:hidden' />
              <span className='xsm:inline-block'>均一じゃないその”欲しい”に</span>
              <span className='xsm:inline-block'>応えてくれるモノに出会うのは至難の業だが、</span>
              <span className='xsm:inline-block'>妥協はしたくない。</span><br className='xsm:hidden' /><br className='sm:hidden' /><br className='sm:hidden' />
              <span className='xsm:inline-block'>同じ想いをもった人たちが集まって、</span>
              <span className='xsm:inline-block'>最高に魅力的な購買体験をしよう。</span>
            </p>
          </div>
        </div>
      </Container>
    </section>
  )
}

const TopNews = () => {
  return (
    <section className='bg-[#E9E5DF] pt-16 pb-48'>
      <div className="my-8 w-1/4">
        <h2 className="flex lg:items-center  py-8
                        lg:before:h-0.5 lg:before:grow lg:before:bg-[#626262] lg:before:mr-16
                        xlg:flex-col xlg:after:block xlg:after:border-b-2 xlg:after:w-[25vw] xlg:after:border-[#626262] xlg:after:pr-8" >
          <p className="block text-[#626262] tracking-widest lg:text-5xl text-2xl xlg:text-right xlg:px-auto">news</p>
        </h2>
      </div>
      <Container>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2024.12.24</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>news</p>
            </div>
            <div className='w-2/3'>
            <ExternalLink href="https://one-suite.jp/onesel_news_241224/">年末年始休業のお知らせ</ExternalLink>
            </div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2024.01.23</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>release</p>
            </div>
            <div className='w-2/3'>
              <ExternalLink href="https://prtimes.jp/main/html/rd/p/000000004.000114987.html">テロップ漫談家が贈る、これまでどこにもなかったテロップ専門書籍を刊行！</ExternalLink>
            </div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2023.12.15</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>news</p>
            </div>
            <div className='w-2/3'>
            <ExternalLink href="https://one-suite.jp/onesel_news_231215/">年末年始休業のお知らせ</ExternalLink>
            </div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2023.08.15</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>news</p>
            </div>
            <div className='w-2/3'>
              <div className='w-2/3'><p>ONESEL株式会社へ社名変更</p></div>
            </div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2023.05.15</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>release</p>
            </div>
            <div className='w-2/3'>
              <ExternalLink href="https://prtimes.jp/main/html/rd/p/000000001.000114987.html">本気で読者の最適解について考えるWebメディア「onesuite（ワンスイート）」をローンチ</ExternalLink>
            </div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2023.02.01</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>news</p>
            </div>
            <div className='w-2/3'><p>映像制作事業をローンチ</p></div>
          </div>
        </div>
        <div>
          <div className='flex md:text-xl text-sm md:ml-4 mb-8 md:pb-4 md:border-b md:border-[#626262]'>
            <div className='md:w-1/5 w-1/3 md:mb-2 mb-4 '>
                <p className='md:inline-block'>2023.02.01</p>
                <p className='md:inline-block badge md:ml-4 md:text-sm text-[0.5rem]'>news</p>
            </div>
            <div className='w-2/3'><p>コーポレートサイト OPEN</p></div>
          </div>
        </div>
      </Container>
    </section>
  )
}

const TopService = () => {
  return (
    <section className='bg-white py-16'>
      <div className="my-8 w-1/4">
        <h2 className="flex lg:items-center  py-8
                        lg:before:h-0.5 lg:before:grow lg:before:bg-[#626262] lg:before:mr-16
                        xlg:flex-col xlg:after:block xlg:after:border-b-2 xlg:after:w-[25vw] xlg:after:border-[#626262] xlg:after:pr-8" >
          <p className="block text-[#626262] tracking-widest lg:text-5xl text-2xl xlg:text-right xlg:px-auto">service</p>
        </h2>
      </div>
      <Container>
        <div className='md:flex items-center justify-center'>
          <div className='lg:w-1/2 text-center'>
              <p className='lg:text-xl sm:text-sm text-[0.7rem]'>メディアプラットフォーム事業</p>
              <a href="https://one-suite.jp/" target='_blank' rel="noreferrer">
                  <StaticImage src='../images/Headers/onesuite_logo.png' alt='onesuite_logo' className='max-w-[640px] md:w-[25vw] sm:w-[45vw] w-[60vw]'/>
              </a>
          </div>
          <p className='lg:w-1/2 md:ml-16 xl:text-2xl xl:leading-loose xxl:text-xl xxl:leading-loose xmd:text-center xmd:mt-4'>
            情報が飽和した時代に<br />
            <span className='xsm:inline-block'>本気で読者の</span>
            <span className='xsm:inline-block'>最適解について考える</span>
            <span className='xsm:inline-block'>Webメディア。</span><br />
            <ExternalLink href="https://one-suite.jp/">one-suite.jp</ExternalLink>
          </p>
        </div>
        <div className='md:flex items-center justify-center mt-24'>
          <div className='lg:w-1/2 text-center'>
              <p className='lg:text-xl sm:text-sm text-[0.7rem]'>映像制作事業</p>
              <StaticImage src='../images/ServicePage/onesel_logo_service.png' alt='onesel_logo' className='max-w-[640px] md:w-[25vw] sm:w-[45vw] w-[60vw]'/>
          </div>
          <p className='lg:w-1/2 md:ml-16 xl:text-2xl xl:leading-loose xxl:text-xl xxl:leading-loose xmd:text-center xmd:mt-4'>
            映像のチカラで、<br />
            <span className='xsm:inline-block'>心動かすクリエイティブ。</span>
            <span className='xsm:inline-block'>視覚で直感的に</span><br />
            魅力を伝える映像を提供。
          </p>
        </div>
      </Container>
      <Link to="/service/" className="block flex justify-end lg:mt-32 w-screen">
        <div className='flex w-2/5 lg:items-center 
                        lg:after:h-4 after:border-b-2 after:border-r-2 lg:after:grow after:skew-x-[45deg] after:border-[#626262] xl:after:ml-16 after:mr-4
                        xlg:after:h-2 xlg:flex-col xlg:w-[25vw]'>
          <p className='tracking-widest lg:text-3xl text-xl xlg:text-left xlg:px-auto'>
              read more
          </p>
        </div>
      </Link>
    </section>
  )
}

const TopContact = () => {
  return (
    <section className='bg-[#E4E4E4] py-16 border-b-2 border-[#626262]'>
      <div className="my-8">
        <h2 className="text-center pt-8" >
          <p className="text-[#626262] tracking-widest lg:text-5xl text-2xl">contact</p>
        </h2>
      </div>
      <Container>
        <div className='flex justify-center text-white'>
          <div className='text-center bg-[#575757] py-4 px-16 rounded-full'>
            <ExternalLink href='https://forms.office.com/r/8wNaSsudcR'>お問い合わせはこちら</ExternalLink>
          </div>
        </div>
        <p className='text-center mt-8 text-xl'>
          <span className='xsm:inline-block'>出稿・制作・取材</span>
          <span className='xsm:inline-block'>に関するご質問や</span>
          <span className='xsm:inline-block'>ご相談はフォームより</span><br className='xsm:hidden' />
          <span className='xsm:inline-block'>お気軽にお問い合わせください。 </span>
        </p>
      </Container>
    </section>
  )
}

export default IndexPage

export function Head({ location }) {
  return (
    <MetaTag
      pathname={location.pathname}
      title='ONESEL'
    >
    </MetaTag>
  )
}